function scrollToo ($e, duration, y) {
    y = y || 0;
    duration = duration || 300;
    var _top = $e.offset().top + y;
    $('html,body').animate({"scrollTop" : _top}, duration);
    return false;
}

function menuScroll () {
    var menu = $('.menuList__inside');
    var but = $('.menuList__toggle');

    menu.find('a').on('click', function(e) {
        let id = $(this).attr('href');
        scrollToo($(id), 300, -60);
        if (menu.hasClass('vis')) menu.removeClass('vis');
        e.preventDefault();
    });

    but.on('click', function() {
        menu.hasClass('vis') ? menu.removeClass('vis') : menu.addClass('vis');
    });

    $(document).on('click touchstart', function(event) {
		var $target = $(event.target);
        if (!$target.is(menu) && !$target.is(but) && !but.find($target).length && menu.hasClass('vis')) but.trigger('click');
    });
}

function menuChange () {
    var header = $('.header_blur');
    var list = $('.menuList');
    var tabs = $('.tabs');
    var val, hashtag;

    val = tabs.find('input:checked').val();
    header.addClass('header_blur'+'_'+val);
    list.addClass('menuList'+'_'+val);

    $('.tabs__radio').on('change', function() {
        val = $(this).val();
        header.attr('class', 'header_blur').addClass('header_blur'+'_'+val);
        list.attr('class', 'menuList').addClass('menuList'+'_'+val);
    });

    hashtag = window.location.hash.substring(1);

    if (hashtag !== '') tabs.find('[data-hash='+hashtag+']').trigger('click');
}

function placeholder () {
    var fields = $('.forma__field .forma__field__itext, .forma__field textarea');
    var selectors = $('.forma__field__select');

    fields.each(function() {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
    });

    selectors.each(function() {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
    });

    fields.on('keyup', function () {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
        else $(this).parent().find('label').show();
    }).on('focusin', function () {
        $(this).parent().addClass('forma__field_light');
    }).on('focusout', function () {
        $(this).parent().removeClass('forma__field_light');
        // if ($(this).val() == '') $(this).parent().removeClass('forma__field_focus');
    });

    selectors.on('change', function() {
        $(this).val() != '' ? $(this).parent().find('label').hide() : $(this).parent().find('label').show();
    });
}

/*********************************
********* + Menu mobile **********
*********************************/

function menuToggle () {
    var menu = $('#mm');
    var toggleBut = $('.menuToggle');

    toggleBut.on('click', function () {
        if (menu.css('display') == 'block') {
            menu.animate({'opacity': 0}, 300, function() { $(this).hide(); });
            //menu.slideUp(300, 'linear');
            toggleBut.removeClass('active');
        }
        else {
            //menu.slideDown(300, 'linear');
            menu.show().animate({'opacity': 1}, 300);
            toggleBut.addClass('active');
        }
    });

    $(window).on('resize', function() {
        setTimeout(function() {
            if (window.innerWidth >= 641) menu.show().css('opacity', 1);
            else if (!toggleBut.hasClass('active')) menu.hide().css('opacity', 0);
        }, 50);
    });

    $(document).on('click touchstart', function(event) {
		var $target = $(event.target);
        if (!$target.is(menu) && !$target.is(toggleBut) && !toggleBut.find($target).length && toggleBut.hasClass('active')) toggleBut.trigger('click');
    });
}

function scrollToForm () {
    var button = $('.head__right .button');
    var forma = $('.request').prev();

    button.on('click touchstart', function(event) {
        scrollToo(forma, 400, -30);
        event.preventDefault();
    })
}

function onEntry(entry) {
    entry.forEach(change => {
        if (change.isIntersecting) {
            change.target.classList.add('elementShow');
        }
    });
}

function animationElements () {
    let options = { threshold: [0.5] };
    let observer = new IntersectionObserver(onEntry, options);
    let elements = document.querySelectorAll('.elementAnimation');
    
    for (let elm of elements) {
        elm.classList.remove('elementShow');
        observer.observe(elm);
    }

    // Удалить CSS-класс square-transition
    /*const element = document.querySelector('.elementAnimation');
    element.classList.remove('elementShow');

    // Добавить наблюдение за появлением элемента
    const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            element.classList.add('elementShow');
            return;
            }
            element.classList.remove('elementShow');
        });
    });
    observer.observe(document.querySelector('.elementAnimation'));*/
}

var tabs = function () {
    tabs.window = $(window);
    tabs.box = $('.tabs');
    tabs.scrollPoint = $('#head').height() + 60;
    tabs.heightWin = document.documentElement.clientHeight + tabs.scrollPoint;

    tabs.fixTabs();
    tabs.winEvents();
}

tabs.calcHeightDoc = function () {
	var scrollHeight = Math.max(
		document.body.scrollHeight, document.documentElement.scrollHeight,
		document.body.offsetHeight, document.documentElement.offsetHeight,
		document.body.clientHeight, document.documentElement.clientHeight
	);
	return scrollHeight;
}

tabs.fixTabs = function () {
    if (tabs.window.scrollTop() >= tabs.scrollPoint && tabs.calcHeightDoc() > tabs.heightWin && window.innerWidth >= 1000) {
		tabs.box.addClass('fix');
	} else {
    	tabs.box.removeClass('fix');
	}
}

tabs.winEvents = function () {
    tabs.window.scroll(function() {
		tabs.fixTabs();
	});
    tabs.window.on('resize', function () {
        setTimeout(function () {
            tabs.fixTabs();
        }, 50);
    });
}

function catalogItemScroll () {
    $('.tabs').find('a').on('click', function(event) {
        if ($(this).data('id') != '') {
            var id = $(this).data('id')
            var element = $('#'+id);
            scrollToo(element, 400, -20);
            event.preventDefault();
        }
    })
}

function catalogItemGallery () {
    $('body').on('click', '.catalog__item__gallery__mini a', function(event) {
        if (!$(this).hasClass('sel')) {
            $(this).parent().find('.sel').removeClass('sel');
            $(this).addClass('sel');
            $(this).parents('.catalog__item__gallery').find('.catalog__item__gallery__main').attr('src', $(this).data('main'));
        }
        event.preventDefault();
    });
}

function sendMsg () {
    var popup = $('.fixPopupMsg');
    var msg = $('.forma__sendMsg');
    var closeBut = $('.fixPopupMsg__close');
    
    var timeout;
    if (msg.find('div').length) {
        var classMsg = msg.find('div').attr('class');
        var textMsg = msg.find('div').text();
        popup.prepend('<div class="'+classMsg+'">'+textMsg+'</div>');
        popup.addClass('vis');
        timeout = setTimeout(function() {
            popup.animate({opacity : 'hide'}, 300, function() {
                $(this).removeClass('vis');
            });
        }, 5000);
        //popup.prepend('div class="'+classMsg+'"></div>')
    }

    closeBut.on('click', function() {
        clearTimeout(timeout);
        popup.animate({opacity : 'hide'}, 300, function() {
            $(this).removeClass('vis');
        })
    });
}

/********************************
********** Form Valid ***********
********************************/

var CheckForms = function (form, button, options) {
    this.form = form;
    this.button = form.find(button);

    this.options = $.extend({}, CheckForms.Defaults, options);

    if (this.options.radio) this.radioChange();

    this.checkEmpty();
    this.submitForm();
    this.removeError();
}

CheckForms.Defaults = {
    errorClass: 'errorField',
    emptyClass: 'emptyField',
    radio: 0,
}

CheckForms.prototype.checkEmpty = function () {
    this.form.find('.requiredField').each(function () {
        if ($(this).val() == '' || $(this).val() == $(this).parent().find('label').text() || ($(this).is('[type = checkbox') && !$(this).is('input:checked'))) $(this).addClass('emptyField');
    });

    this.checkAllElements();
}

CheckForms.prototype.checkCorrect = function () {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    var patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    this.form.find('.requiredField').each(function () {
        var item = $(this);
        var itemValue = item.val();
        var mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        var dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        /**
         * @TODO:
         * 1. Get field type
         * 2. Check field by correct method (checkInput, checkRadio, checkCheckbox, checkEmail, checkDate)
         * 
         * checkDate by data-validate="date" + data-validatePattern="yyyy.mm.dd". Example: yyyy.mm.dd || dd.mm.yyyy
         **/ 
        if (!itemValue || !mailChecked || !dateChecked || itemValue == item.parent().find('label').text() || (item.is('[type = checkbox') && !item.is('input:checked'))) {
            item.addClass('errorField');
        }
        //if (item.is('[type = checkbox') && item.is('input:checked')) item.addClass('errorField');
    });

    this.checkAllElements();
}

CheckForms.prototype.removeError = function () {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    var patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    //return patternEmail.test(value);

    this.form.on('keyup blur change', '.requiredField', function () {
        var item = $(this);
        var itemValue = item.val();
        var mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        var dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        if (itemValue && itemValue != item.parent().find('label').text() && mailChecked && dateChecked) {
            item.removeClass('errorField emptyField');
        } else {
            itemValue == item.parent().find('label').text() ? item.addClass('emptyField') : item.addClass('errorField');
            if (!dateChecked) item.addClass('errorField');
        }

        if (item.is('[type = checkbox')) {
            item.is('input:checked') ? item.removeClass('errorField emptyField') : item.addClass('errorField');
        }

        var form = item.parents('form');
        var submitButton = form.find('.button_submit');
        if (!form.find('.emptyField').length && !form.find('.errorField').length) {
            submitButton.removeClass('button_disabled');
        } else {
            submitButton.addClass('button_disabled');
        }
    });
}

CheckForms.prototype.checkEmail = function (value) {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    return patternEmail.test(value);
}

CheckForms.prototype.checkAllElements = function () {
    if (!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
        this.button.removeClass('button_disabled');
    } else {
        this.button.addClass('button_disabled');
    }
}

CheckForms.prototype.submitForm = function () {
    this.button.click(function (event) {
        //this.checkEmpty();
        this.checkCorrect();
        event.preventDefault();
        if (!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
            if (this.button.parent().find('.cssload').length) this.button.parent().addClass('forma__field_loading');
            this.form.submit();
        }
    }.bind(this));
}

function execQuestForm() {
    var contactsForm = new CheckForms($('#questForm'), '.button_submit');
}

function execContactsForm() {
    var contactsForm = new CheckForms($('#feedForm'), '.button_submit');
}

function execOrderForm() {
    var contactsForm = new CheckForms($('#orderForm'), '.button_submit');
}

function popupQuestForm() {
    $('.popupQuestForm').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#name',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#name';
				}
			}
		}
    });
}

const onlineBooking = function() {
    onlineBooking.$tabs = $('.radioTab');
    onlineBooking.$dateInput = $('#datetimepicker');
    onlineBooking.$timeSelect = $('#time');
    onlineBooking.$quantity = $('#quantity');
    onlineBooking.$price = $('#price');
    onlineBooking.$services = $('[name = service]');
    onlineBooking.$calcButtons = $('.radioTab__box__hours__minus, .radioTab__box__hours__plus');

    onlineBooking.currency = $('meta[name="currency-name"]').attr('content');

    onlineBooking.popupBookForm();
    onlineBooking.calc();
    if ($('#datetimepicker').length) onlineBooking.execDate();
    new CheckForms($('#bookForm'), '.button_submit');
}

onlineBooking.calcTotal = function () {
    return parseInt(onlineBooking.$quantity.val()) * parseInt(onlineBooking.$price.val());
}

onlineBooking.total = function () {
    const id = onlineBooking.$services.parent().find('> input[type="radio"]:checked').val();
    const tab = onlineBooking.$tabs.find('[data-id=' + id+ ']');

    tab.find('.totalSum').text(numberFormat(onlineBooking.calcTotal(), {decimals: 0, thousands_sep: ' '}) +' '+ onlineBooking.currency);
}

onlineBooking.calc = function () {
    onlineBooking.$calcButtons.on('click', function() {
        let num = parseInt(onlineBooking.$quantity.val());
        if ($(this).hasClass('radioTab__box__hours__plus')) num ++;
        else if (num > 1) {
            num --;
        }
        $(this).parent().find('span').text(num);
        onlineBooking.$quantity.val(num);
        onlineBooking.total();
    })
}

onlineBooking.pullPrice = function() {
    const service = onlineBooking.$services.parent().find('> input[type="radio"]:checked').val();

    $.ajax({
        url: $('meta[name="price-url"]').attr('content'),
        method: 'GET',
        data: {'service' : service},
        //dataType: 'json',
        success: function (data) {
            // onlineBooking.constructTimeSelect(JSON.parse("[" + data + "]"));
            onlineBooking.$price.val(data);
            onlineBooking.total();
        }
    });
}

/** date and time fields reset */

onlineBooking.dateTimeReset = function() {
    onlineBooking.$dateInput.val('');
    onlineBooking.$timeSelect.empty().parent().addClass('forma__field_hide');
}

/** time select options */
onlineBooking.constructTimeSelect = function(options) {
    onlineBooking.$timeSelect.html('<option value="" disabled selected>Время *</option>');
    for (let i = 0; i < options.length; i++) {
        onlineBooking.$timeSelect.append('<option val="'+options[i]+'">'+options[i]+'</option>');
    }
}

/** pull of allow's time */
onlineBooking.pullTime = function() {
    const quantity = parseInt(onlineBooking.$quantity.val());
    const service = onlineBooking.$services.val();
    if (onlineBooking.$dateInput.val() != '') {
        onlineBooking.$timeSelect.parent().addClass('forma__field_loading forma__field_hide');
        $.ajax({
            url: $('meta[name="time-url"]').attr('content'),
            method: 'GET',
            data: {'service' : service, 'quantity' : quantity, 'date': onlineBooking.$dateInput.val()},
            //dataType: 'json',
            success: function (data) {
                // onlineBooking.constructTimeSelect(data);
                onlineBooking.constructTimeSelect(JSON.parse("[" + data + "]"));
                onlineBooking.$timeSelect.parent().removeClass('forma__field_loading forma__field_hide');
            }
        });
    }
}

/** exec datetimepicker */
onlineBooking.execDate = function() {
    $.datetimepicker.setLocale('ru');
    onlineBooking.$dateInput.datetimepicker(datePickerOptions);
}

onlineBooking.popupBookForm = function() {
    $('.popupBookForm').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#name',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#name';
				}
			},
            elementParse: function(item) {
              // Function will fire for each target element
              // "item.el" is a target DOM element (if present)
              // "item.src" is a source that you may modify
          
                //console.log('Parsing content. Item object that is being parsed:', item);
                // onlineBooking.pullPrice();
            },
		}
    });
}

function numberFormat (number, cfg) {
	function obj_merge(obj_first, obj_second){
		var obj_return = {};
		for (key in obj_first){
			if (typeof obj_second[key] !== 'undefined') obj_return[key] = obj_second[key];
			else obj_return[key] = obj_first[key];
		}
		return obj_return;
	}
	function thousands_sep(num, sep){
		if (num.length <= 3) return num;
		var count = num.length; var num_parser = ''; var count_digits = 0;
		for (var p = (count - 1); p >= 0; p--){
			var num_digit = num.substr(p, 1);
			if (count_digits % 3 == 0 && count_digits != 0 && !isNaN(parseFloat(num_digit))) num_parser = sep + num_parser;
			num_parser = num_digit + num_parser;
			count_digits++;
		}
		return num_parser;
	}
	if (typeof number !== 'number'){
		number = parseFloat(number);
		if (isNaN(number)) return false;
	}
	var cfg_default = {before: '', after: '', decimals: 2, dec_point: '.', thousands_sep: ','};
	if (cfg && typeof cfg === 'object'){ cfg = obj_merge(cfg_default, cfg); } else cfg = cfg_default;
	number = number.toFixed(cfg.decimals);
	if (number.indexOf('.') != -1){
		var number_arr = number.split('.');
		var number = thousands_sep(number_arr[0], cfg.thousands_sep) + cfg.dec_point + number_arr[1];
	} else var number = thousands_sep(number, cfg.thousands_sep);
	return cfg.before + number + cfg.after;
}

/********************************
******** END Form Valid *********
********************************/

function initDGMap() {
    var map, marker, popup;

    DG.then(function () {
        map = DG.map('map', {
            center: [46.930751, 142.647551],
            zoom: 17,
            scrollWheelZoom: false
        });

        popup = DG.popup({
            maxWidth: 350,
            minWidth: 280,
            className: 'map__popup'
        })
        .setLatLng([46.930993, 142.648093])
        .setContent('<img src="/assets/tmp-images/address.jpg" alt=""><p>с. Троицкое, ДСУ-1, 9<br />Тел.: <nobr>(4242) 300-433</nobr><br /></p>');

        marker = DG.marker([46.929881, 142.647698]).addTo(map);
        marker.bindPopup(popup);
    });
}

function initMap() {
    var mapCenter = $('meta[name="contacts:map-center"]').attr('content');

    ymaps.ready(function () {
        var myMap = new ymaps.Map('map', {
            center: mapCenter.split(','),
            zoom: 16,
            controls: ['fullscreenControl', 'zoomControl', 'geolocationControl']
        });
        myMap.behaviors.disable(['scrollZoom', 'rightMouseButtonMagnifier', 'multiTouch']);

    	for (var key in places) {

    		placesExec = new ymaps.Placemark(
    		    places[key],
    		    {
    		        balloonContentHeader: '<div class="mcapt">' + places[key][2] + '</div>',
    		        // balloonContentBody: '<div class="mimage">' + places[key][3] + '</div>',
    		        balloonContentFooter: '<div class="mbm">' + places[key][3] + '</div>'

    		    },
    		    {
                    preset: 'islands#dotIcon', 
                    iconColor: '#574220'
    		    }
    		);
    		myMap.geoObjects.add(placesExec);
    	}
    });
}

function popupGal() {
    var options = {
        delegate: 'a',
        type: 'image',
        tLoading: 'Загружено #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            tCounter: '<span class="mfp-counter">%curr% из %total%</span>', // markup of counter
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
            titleSrc: function(item) {
                return item.el.attr('title') + '<small></small>';
            }
        }
    };
    $('.popup-gallery').each(function() {
        $(this).magnificPopup(options);
    });
}

x.exe['option-default'] = ['menuToggle()', /*'placeholder()',*/ 'animationElements()', 'onlineBooking()'];
x.exe['option-index'] = ['execOrderForm()', 'sendMsg()', 'scrollToForm()', 'initDGMap()'/*, 'initMap()'*/];
x.exe['option-contacts'] = ['initMap()', 'execContactsForm()'];
x.exe['option-content'] = ['popupGal()'];
x.exe['option-consult'] = ['popupCallForm()', 'execCalcForm()', 'popupQuestForm()', 'execQuestForm()', 'sendMsg()'];
x.exe['option-catalog-item'] = ['catalogItemGallery()', 'popupGal()', 'catalogItemScroll()', 'tabs()']
x.exe['option-menu'] = ['menuScroll()', 'menuChange()'];